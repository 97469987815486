import '@config/sentry';

import { MuiProvider } from '@providers/MuiProvider';
import { SWRProvider } from '@providers/SWRProvider';

import { Landing } from './pages/Landing';
import { Teaser } from './pages/Teaser';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

const router = createBrowserRouter([
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: '/get_notified',
    element: <Teaser />,
  },
]);

export function App() {
  return (
    <MuiProvider>
      <SWRProvider>
        <RouterProvider router={router} />
      </SWRProvider>
    </MuiProvider>
  );
}
